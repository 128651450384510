import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Button,
  Grid,
  Text
} from '../components/styled'

export default ({ data }) => {
  //console.log(data)
  const page = data.markdownRemark
  return (
  <Layout>  
    <SEO
      title={page.frontmatter.title}
      page={page}
    />
  	<Container>
  		<Container.Inner narrow>
		    <Title as="h1">{page.frontmatter.title}</Title>
		    <Content full-width dangerouslySetInnerHTML={{ __html: page.html }} />
		  </Container.Inner>
	  </Container>
  </Layout>
)}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }    
`
